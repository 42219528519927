//@ts-nocheck
/**
 * AUTO_GENERATED Do not change this file directly, use config.ts file instead
 *
 * @version 6
 */

import {
  QueryClient,
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { AxiosRequestConfig } from "axios";
import { RequestError, SwaggerResponse } from "./config";

import {
  getBalanceGet_balance,
  getBank_accountsGet_iban_data,
  getBank_accountsGet_pendings,
  getCaptchaGet,
  getCodesError,
  getCodesSuccess,
  getConfigGet_actions,
  getConfigGet_permissions,
  getDashboard_dataGet_data,
  getDashboard_dataSystem_health,
  getDepositGet_deposits_crypto,
  getDepositGet_deposits_irt,
  getDepositGet_deposit_address,
  getExchange_dataCoins,
  getExchange_dataMarkets,
  getKycGet_pending_kyc_level1,
  getKycGet_pending_kyc_selfie_level2,
  getManagement_dashboard_dataGet_balance_sum,
  getManagement_dashboard_dataGet_data,
  getOperatorsCurrent_operator_data,
  getOperatorsGet_actions_history,
  getOperatorsGet_operator,
  getOperatorsOnline_operators,
  getOperatorsPing,
  getOrdersGet_orders,
  getOrdersGet_trades,
  getSwagger,
  getTickets,
  getTicketsGet_agents,
  getTicketsGet_ticket_data,
  getUsersDownload_file,
  getUsersGet_actions,
  getUsersGet_bank_accounts,
  getUsersGet_files_list,
  getUsersGet_users,
  getWithdrawGet_pendings,
  getWithdrawGet_withdraw_crypto,
  getWithdrawGet_withdraw_irt,
  postLogin,
  postOperatorsAdd_operator,
  postTicketsCreate,
  postUsersActivate_tagged_deposit,
  postUsersSet_4872_wait,
  postUsersSet_ir_ip_check,
  postUsersUpload_file,
  putBalanceDecrease_balance,
  putBalanceIncrease_balance,
  putBank_accountsAccept_card,
  putBank_accountsAccept_iban,
  putBank_accountsReject_card,
  putBank_accountsReject_iban,
  putExchange_dataUpdate_coins_data,
  putKycLevel1Accept_kyc_document,
  putKycLevel1Reject_kyc_document,
  putLandline_phoneReject,
  putLandline_phoneVerify,
  putOperatorsReset_2fa,
  putOperatorsUpdate_operator,
  putTicketsReply,
  putTicketsUpdate,
  putUsersAdd_description,
  putUsersSet_auto_withdraw_permission,
  putUsersSet_birthday,
  putUsersSet_daily_crypto_withdraw_limit,
  putUsersSet_daily_irt_withdraw_limit,
  putUsersSet_deposit_permission,
  putUsersSet_gender,
  putUsersSet_monthly_withdraw_limit,
  putUsersSet_name,
  putUsersSet_trade_permission,
  putUsersSet_upload_permission,
  putUsersSet_vip_level,
  putUsersSet_withdraw_permission,
  putUsersToggle_ban,
  putUsersToggle_withdraw_wait_time,
  putWithdrawSet_crypto_to_auto,
  putWithdrawSet_crypto_to_not_wait,
  putWithdrawSet_crypto_txhash,
  putWithdrawSet_description_crypto,
  putWithdrawSet_description_irt,
  putWithdrawSet_irt_to_auto,
  putWithdrawSet_irt_to_not_wait,
  putWithdrawSet_status_crypto,
  putWithdrawSet_status_irt,
} from "./services";
import type {
  GetBalanceGetBalance,
  GetBalanceGet_balanceQueryParams,
  GetBankAccountsGetIbanData,
  GetBankAccountsGetPendings,
  GetBank_accountsGet_iban_dataQueryParams,
  GetCaptchaGet,
  GetConfigGetActions,
  GetConfigGetPermissions,
  GetDashboardDataGetData,
  GetDashboardDataSystemHealth,
  GetDepositGetDepositAddress,
  GetDepositGetDepositsCrypto,
  GetDepositGetDepositsIrt,
  GetDepositGet_deposits_cryptoQueryParams,
  GetDepositGet_deposits_irtQueryParams,
  GetDepositGet_deposit_addressQueryParams,
  GetExchangeDataCoins,
  GetExchangeDataMarkets,
  GetManagementDashboardDtaGetBalanceSum,
  GetManagementDashboardDtaGetData,
  GetOperatorsCurrentOperatorData,
  GetOperatorsGetActionsHistory,
  GetOperatorsGetOperator,
  GetOperatorsGet_actions_historyQueryParams,
  GetOperatorsGet_operatorQueryParams,
  GetOperatorsOnlineOperators,
  GetOperatorsPing,
  GetOrdersGetOrders,
  GetOrdersGetTrades,
  GetOrdersGet_ordersQueryParams,
  GetOrdersGet_tradesQueryParams,
  GetTickets,
  GetTicketsGetAgentsData,
  GetTicketsGetTicketData,
  GetTicketsGet_ticket_dataQueryParams,
  GetTicketsQueryParams,
  GetUsersDownloadFile,
  GetUsersDownload_fileQueryParams,
  GetUsersGetActions,
  GetUsersGetBankAccounts,
  GetUsersGetFilesList,
  GetUsersGetPendingKyc,
  GetUsersGetPendingKycSelfieLevel2,
  GetUsersGetUsers,
  GetUsersGet_actionsQueryParams,
  GetUsersGet_bank_accountsQueryParams,
  GetUsersGet_files_listQueryParams,
  GetUsersGet_usersQueryParams,
  GetWithdrawGetPendings,
  GetWithdrawGetWithdrawCrypto,
  GetWithdrawGetWithdrawIrt,
  GetWithdrawGet_withdraw_cryptoQueryParams,
  GetWithdrawGet_withdraw_irtQueryParams,
  PostLogin,
  PostOperatorsAddOperator,
  Success,
} from "./types";

export type SwaggerTypescriptMutationDefaultParams<TExtra> = {
  _extraVariables?: TExtra;
  configOverride?: AxiosRequestConfig;
};
type SwaggerTypescriptUseQueryOptions<TData> = UseQueryOptions<
  SwaggerResponse<TData>,
  RequestError | Error
>;

type SwaggerTypescriptUseMutationOptions<TData, TRequest, TExtra> =
  UseMutationOptions<
    SwaggerResponse<TData>,
    RequestError | Error,
    TRequest & SwaggerTypescriptMutationDefaultParams<TExtra>
  >;

type SwaggerTypescriptUseMutationOptionsVoid<TData, TExtra> =
  UseMutationOptions<
    SwaggerResponse<TData>,
    RequestError | Error,
    SwaggerTypescriptMutationDefaultParams<TExtra> | void
  >;

/** Get specific user balance data */
export const useGetBalanceGet_balance = (
  queryParams: GetBalanceGet_balanceQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<GetBalanceGetBalance>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetBalanceGet_balance.info(
    queryParams,
    configOverride,
  );
  return useQuery(key, fun, options);
};
useGetBalanceGet_balance.info = (
  queryParams: GetBalanceGet_balanceQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getBalanceGet_balance.key, queryParams] as QueryKey,
    fun: () =>
      getBalanceGet_balance(
        queryParams,

        configOverride,
      ),
  };
};
useGetBalanceGet_balance.prefetch = (
  client: QueryClient,
  queryParams: GetBalanceGet_balanceQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<GetBalanceGetBalance>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetBalanceGet_balance.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};

/** Get iban data */
export const useGetBank_accountsGet_iban_data = (
  queryParams: GetBank_accountsGet_iban_dataQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<GetBankAccountsGetIbanData>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetBank_accountsGet_iban_data.info(
    queryParams,
    configOverride,
  );
  return useQuery(key, fun, options);
};
useGetBank_accountsGet_iban_data.info = (
  queryParams: GetBank_accountsGet_iban_dataQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getBank_accountsGet_iban_data.key, queryParams] as QueryKey,
    fun: () =>
      getBank_accountsGet_iban_data(
        queryParams,

        configOverride,
      ),
  };
};
useGetBank_accountsGet_iban_data.prefetch = (
  client: QueryClient,
  queryParams: GetBank_accountsGet_iban_dataQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<GetBankAccountsGetIbanData>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetBank_accountsGet_iban_data.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};

/** Get pending bank accounts, card and iban */
export const useGetBank_accountsGet_pendings = (
  options?: SwaggerTypescriptUseQueryOptions<GetBankAccountsGetPendings>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetBank_accountsGet_pendings.info(configOverride);
  return useQuery(key, fun, options);
};
useGetBank_accountsGet_pendings.info = (
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getBank_accountsGet_pendings.key] as QueryKey,
    fun: () => getBank_accountsGet_pendings(configOverride),
  };
};
useGetBank_accountsGet_pendings.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<GetBankAccountsGetPendings>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetBank_accountsGet_pendings.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};

/** Get captcha image */
export const useGetCaptchaGet = (
  options?: SwaggerTypescriptUseQueryOptions<GetCaptchaGet>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetCaptchaGet.info(configOverride);
  return useQuery(key, fun, options);
};
useGetCaptchaGet.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getCaptchaGet.key] as QueryKey,
    fun: () => getCaptchaGet(configOverride),
  };
};
useGetCaptchaGet.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<GetCaptchaGet>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetCaptchaGet.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};

/** Get ariomex error codes json */
export const useGetCodesError = (
  options?: SwaggerTypescriptUseQueryOptions<{ [x in string | number]: any }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetCodesError.info(configOverride);
  return useQuery(key, fun, options);
};
useGetCodesError.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getCodesError.key] as QueryKey,
    fun: () => getCodesError(configOverride),
  };
};
useGetCodesError.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<{ [x in string | number]: any }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetCodesError.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};

/** Get ariomex success codes json */
export const useGetCodesSuccess = (
  options?: SwaggerTypescriptUseQueryOptions<{ [x in string | number]: any }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetCodesSuccess.info(configOverride);
  return useQuery(key, fun, options);
};
useGetCodesSuccess.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getCodesSuccess.key] as QueryKey,
    fun: () => getCodesSuccess(configOverride),
  };
};
useGetCodesSuccess.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<{ [x in string | number]: any }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetCodesSuccess.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};

/** Get operators action names and translations */
export const useGetConfigGet_actions = (
  options?: SwaggerTypescriptUseQueryOptions<GetConfigGetActions>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetConfigGet_actions.info(configOverride);
  return useQuery(key, fun, options);
};
useGetConfigGet_actions.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getConfigGet_actions.key] as QueryKey,
    fun: () => getConfigGet_actions(configOverride),
  };
};
useGetConfigGet_actions.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<GetConfigGetActions>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetConfigGet_actions.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};

/** Get ariomex swagger json */
export const useGetConfigGet_permissions = (
  options?: SwaggerTypescriptUseQueryOptions<GetConfigGetPermissions>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetConfigGet_permissions.info(configOverride);
  return useQuery(key, fun, options);
};
useGetConfigGet_permissions.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getConfigGet_permissions.key] as QueryKey,
    fun: () => getConfigGet_permissions(configOverride),
  };
};
useGetConfigGet_permissions.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<GetConfigGetPermissions>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetConfigGet_permissions.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};

/** Get dashboard data */
export const useGetDashboard_dataGet_data = (
  options?: SwaggerTypescriptUseQueryOptions<GetDashboardDataGetData>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetDashboard_dataGet_data.info(configOverride);
  return useQuery(key, fun, options);
};
useGetDashboard_dataGet_data.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getDashboard_dataGet_data.key] as QueryKey,
    fun: () => getDashboard_dataGet_data(configOverride),
  };
};
useGetDashboard_dataGet_data.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<GetDashboardDataGetData>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetDashboard_dataGet_data.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};

/** Get system health */
export const useGetDashboard_dataSystem_health = (
  options?: SwaggerTypescriptUseQueryOptions<GetDashboardDataSystemHealth>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetDashboard_dataSystem_health.info(configOverride);
  return useQuery(key, fun, options);
};
useGetDashboard_dataSystem_health.info = (
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getDashboard_dataSystem_health.key] as QueryKey,
    fun: () => getDashboard_dataSystem_health(configOverride),
  };
};
useGetDashboard_dataSystem_health.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<GetDashboardDataSystemHealth>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetDashboard_dataSystem_health.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};

/** Get specific user deposit address data */
export const useGetDepositGet_deposit_address = (
  queryParams: GetDepositGet_deposit_addressQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<GetDepositGetDepositAddress>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetDepositGet_deposit_address.info(
    queryParams,
    configOverride,
  );
  return useQuery(key, fun, options);
};
useGetDepositGet_deposit_address.info = (
  queryParams: GetDepositGet_deposit_addressQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getDepositGet_deposit_address.key, queryParams] as QueryKey,
    fun: () =>
      getDepositGet_deposit_address(
        queryParams,

        configOverride,
      ),
  };
};
useGetDepositGet_deposit_address.prefetch = (
  client: QueryClient,
  queryParams: GetDepositGet_deposit_addressQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<GetDepositGetDepositAddress>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetDepositGet_deposit_address.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};

/** Get crypto deposits data based on filters */
export const useGetDepositGet_deposits_crypto = (
  queryParams?: GetDepositGet_deposits_cryptoQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<GetDepositGetDepositsCrypto>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetDepositGet_deposits_crypto.info(
    queryParams,
    configOverride,
  );
  return useQuery(key, fun, options);
};
useGetDepositGet_deposits_crypto.info = (
  queryParams?: GetDepositGet_deposits_cryptoQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getDepositGet_deposits_crypto.key, queryParams] as QueryKey,
    fun: () =>
      getDepositGet_deposits_crypto(
        queryParams,

        configOverride,
      ),
  };
};
useGetDepositGet_deposits_crypto.prefetch = (
  client: QueryClient,
  queryParams?: GetDepositGet_deposits_cryptoQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<GetDepositGetDepositsCrypto>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetDepositGet_deposits_crypto.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};

/** Get irt deposits data based on filters */
export const useGetDepositGet_deposits_irt = (
  queryParams?: GetDepositGet_deposits_irtQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<GetDepositGetDepositsIrt>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetDepositGet_deposits_irt.info(
    queryParams,
    configOverride,
  );
  return useQuery(key, fun, options);
};
useGetDepositGet_deposits_irt.info = (
  queryParams?: GetDepositGet_deposits_irtQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getDepositGet_deposits_irt.key, queryParams] as QueryKey,
    fun: () =>
      getDepositGet_deposits_irt(
        queryParams,

        configOverride,
      ),
  };
};
useGetDepositGet_deposits_irt.prefetch = (
  client: QueryClient,
  queryParams?: GetDepositGet_deposits_irtQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<GetDepositGetDepositsIrt>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetDepositGet_deposits_irt.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};

/** Get all coins data */
export const useGetExchange_dataCoins = (
  options?: SwaggerTypescriptUseQueryOptions<GetExchangeDataCoins>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetExchange_dataCoins.info(configOverride);
  return useQuery(key, fun, options);
};
useGetExchange_dataCoins.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getExchange_dataCoins.key] as QueryKey,
    fun: () => getExchange_dataCoins(configOverride),
  };
};
useGetExchange_dataCoins.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<GetExchangeDataCoins>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetExchange_dataCoins.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};

/** Get all markets data */
export const useGetExchange_dataMarkets = (
  options?: SwaggerTypescriptUseQueryOptions<GetExchangeDataMarkets>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetExchange_dataMarkets.info(configOverride);
  return useQuery(key, fun, options);
};
useGetExchange_dataMarkets.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getExchange_dataMarkets.key] as QueryKey,
    fun: () => getExchange_dataMarkets(configOverride),
  };
};
useGetExchange_dataMarkets.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<GetExchangeDataMarkets>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetExchange_dataMarkets.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};

/** Get_pending_kyc_level1 */
export const useGetKycGet_pending_kyc_level1 = (
  options?: SwaggerTypescriptUseQueryOptions<GetUsersGetPendingKyc>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetKycGet_pending_kyc_level1.info(configOverride);
  return useQuery(key, fun, options);
};
useGetKycGet_pending_kyc_level1.info = (
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getKycGet_pending_kyc_level1.key] as QueryKey,
    fun: () => getKycGet_pending_kyc_level1(configOverride),
  };
};
useGetKycGet_pending_kyc_level1.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<GetUsersGetPendingKyc>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetKycGet_pending_kyc_level1.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};

/** Get_pending_kyc_selfie_level2 */
export const useGetKycGet_pending_kyc_selfie_level2 = (
  options?: SwaggerTypescriptUseQueryOptions<GetUsersGetPendingKycSelfieLevel2>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetKycGet_pending_kyc_selfie_level2.info(configOverride);
  return useQuery(key, fun, options);
};
useGetKycGet_pending_kyc_selfie_level2.info = (
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getKycGet_pending_kyc_selfie_level2.key] as QueryKey,
    fun: () => getKycGet_pending_kyc_selfie_level2(configOverride),
  };
};
useGetKycGet_pending_kyc_selfie_level2.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<GetUsersGetPendingKycSelfieLevel2>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetKycGet_pending_kyc_selfie_level2.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};

/** Get sum of all users balance for each coin */
export const useGetManagement_dashboard_dataGet_balance_sum = (
  options?: SwaggerTypescriptUseQueryOptions<GetManagementDashboardDtaGetBalanceSum>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetManagement_dashboard_dataGet_balance_sum.info(configOverride);
  return useQuery(key, fun, options);
};
useGetManagement_dashboard_dataGet_balance_sum.info = (
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getManagement_dashboard_dataGet_balance_sum.key] as QueryKey,
    fun: () => getManagement_dashboard_dataGet_balance_sum(configOverride),
  };
};
useGetManagement_dashboard_dataGet_balance_sum.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<GetManagementDashboardDtaGetBalanceSum>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetManagement_dashboard_dataGet_balance_sum.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};

/** Get management dashboard data */
export const useGetManagement_dashboard_dataGet_data = (
  options?: SwaggerTypescriptUseQueryOptions<GetManagementDashboardDtaGetData>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetManagement_dashboard_dataGet_data.info(configOverride);
  return useQuery(key, fun, options);
};
useGetManagement_dashboard_dataGet_data.info = (
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getManagement_dashboard_dataGet_data.key] as QueryKey,
    fun: () => getManagement_dashboard_dataGet_data(configOverride),
  };
};
useGetManagement_dashboard_dataGet_data.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<GetManagementDashboardDtaGetData>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetManagement_dashboard_dataGet_data.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};

/** Get current logged-in operator data */
export const useGetOperatorsCurrent_operator_data = (
  options?: SwaggerTypescriptUseQueryOptions<GetOperatorsCurrentOperatorData>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetOperatorsCurrent_operator_data.info(configOverride);
  return useQuery(key, fun, options);
};
useGetOperatorsCurrent_operator_data.info = (
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getOperatorsCurrent_operator_data.key] as QueryKey,
    fun: () => getOperatorsCurrent_operator_data(configOverride),
  };
};
useGetOperatorsCurrent_operator_data.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<GetOperatorsCurrentOperatorData>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetOperatorsCurrent_operator_data.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};

/** Get operator data */
export const useGetOperatorsGet_actions_history = (
  queryParams?: GetOperatorsGet_actions_historyQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<GetOperatorsGetActionsHistory>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetOperatorsGet_actions_history.info(
    queryParams,
    configOverride,
  );
  return useQuery(key, fun, options);
};
useGetOperatorsGet_actions_history.info = (
  queryParams?: GetOperatorsGet_actions_historyQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getOperatorsGet_actions_history.key, queryParams] as QueryKey,
    fun: () =>
      getOperatorsGet_actions_history(
        queryParams,

        configOverride,
      ),
  };
};
useGetOperatorsGet_actions_history.prefetch = (
  client: QueryClient,
  queryParams?: GetOperatorsGet_actions_historyQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<GetOperatorsGetActionsHistory>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetOperatorsGet_actions_history.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};

/** Get operator data */
export const useGetOperatorsGet_operator = (
  queryParams?: GetOperatorsGet_operatorQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<GetOperatorsGetOperator>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetOperatorsGet_operator.info(
    queryParams,
    configOverride,
  );
  return useQuery(key, fun, options);
};
useGetOperatorsGet_operator.info = (
  queryParams?: GetOperatorsGet_operatorQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getOperatorsGet_operator.key, queryParams] as QueryKey,
    fun: () =>
      getOperatorsGet_operator(
        queryParams,

        configOverride,
      ),
  };
};
useGetOperatorsGet_operator.prefetch = (
  client: QueryClient,
  queryParams?: GetOperatorsGet_operatorQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<GetOperatorsGetOperator>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetOperatorsGet_operator.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};

/** Get online operators */
export const useGetOperatorsOnline_operators = (
  options?: SwaggerTypescriptUseQueryOptions<GetOperatorsOnlineOperators>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetOperatorsOnline_operators.info(configOverride);
  return useQuery(key, fun, options);
};
useGetOperatorsOnline_operators.info = (
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getOperatorsOnline_operators.key] as QueryKey,
    fun: () => getOperatorsOnline_operators(configOverride),
  };
};
useGetOperatorsOnline_operators.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<GetOperatorsOnlineOperators>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetOperatorsOnline_operators.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};

/** Ping operators */
export const useGetOperatorsPing = (
  options?: SwaggerTypescriptUseQueryOptions<GetOperatorsPing>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetOperatorsPing.info(configOverride);
  return useQuery(key, fun, options);
};
useGetOperatorsPing.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getOperatorsPing.key] as QueryKey,
    fun: () => getOperatorsPing(configOverride),
  };
};
useGetOperatorsPing.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<GetOperatorsPing>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetOperatorsPing.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};

/** Get Orders */
export const useGetOrdersGet_orders = (
  queryParams?: GetOrdersGet_ordersQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<GetOrdersGetOrders>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetOrdersGet_orders.info(queryParams, configOverride);
  return useQuery(key, fun, options);
};
useGetOrdersGet_orders.info = (
  queryParams?: GetOrdersGet_ordersQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getOrdersGet_orders.key, queryParams] as QueryKey,
    fun: () =>
      getOrdersGet_orders(
        queryParams,

        configOverride,
      ),
  };
};
useGetOrdersGet_orders.prefetch = (
  client: QueryClient,
  queryParams?: GetOrdersGet_ordersQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<GetOrdersGetOrders>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetOrdersGet_orders.info(queryParams, configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};

/** Get Trades */
export const useGetOrdersGet_trades = (
  queryParams?: GetOrdersGet_tradesQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<GetOrdersGetTrades>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetOrdersGet_trades.info(queryParams, configOverride);
  return useQuery(key, fun, options);
};
useGetOrdersGet_trades.info = (
  queryParams?: GetOrdersGet_tradesQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getOrdersGet_trades.key, queryParams] as QueryKey,
    fun: () =>
      getOrdersGet_trades(
        queryParams,

        configOverride,
      ),
  };
};
useGetOrdersGet_trades.prefetch = (
  client: QueryClient,
  queryParams?: GetOrdersGet_tradesQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<GetOrdersGetTrades>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetOrdersGet_trades.info(queryParams, configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};

/** Get ariomex swagger json */
export const useGetSwagger = (
  options?: SwaggerTypescriptUseQueryOptions<{ [x in string | number]: any }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetSwagger.info(configOverride);
  return useQuery(key, fun, options);
};
useGetSwagger.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getSwagger.key] as QueryKey,
    fun: () => getSwagger(configOverride),
  };
};
useGetSwagger.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<{ [x in string | number]: any }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetSwagger.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};

/** Get tickets */
export const useGetTickets = (
  queryParams?: GetTicketsQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<GetTickets>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetTickets.info(queryParams, configOverride);
  return useQuery(key, fun, options);
};
useGetTickets.info = (
  queryParams?: GetTicketsQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getTickets.key, queryParams] as QueryKey,
    fun: () =>
      getTickets(
        queryParams,

        configOverride,
      ),
  };
};
useGetTickets.prefetch = (
  client: QueryClient,
  queryParams?: GetTicketsQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<GetTickets>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetTickets.info(queryParams, configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};

/** Get Agnets Data */
export const useGetTicketsGet_agents = (
  options?: SwaggerTypescriptUseQueryOptions<GetTicketsGetAgentsData>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetTicketsGet_agents.info(configOverride);
  return useQuery(key, fun, options);
};
useGetTicketsGet_agents.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getTicketsGet_agents.key] as QueryKey,
    fun: () => getTicketsGet_agents(configOverride),
  };
};
useGetTicketsGet_agents.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<GetTicketsGetAgentsData>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetTicketsGet_agents.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};

/** Get ticket data */
export const useGetTicketsGet_ticket_data = (
  queryParams: GetTicketsGet_ticket_dataQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<GetTicketsGetTicketData>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetTicketsGet_ticket_data.info(
    queryParams,
    configOverride,
  );
  return useQuery(key, fun, options);
};
useGetTicketsGet_ticket_data.info = (
  queryParams: GetTicketsGet_ticket_dataQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getTicketsGet_ticket_data.key, queryParams] as QueryKey,
    fun: () =>
      getTicketsGet_ticket_data(
        queryParams,

        configOverride,
      ),
  };
};
useGetTicketsGet_ticket_data.prefetch = (
  client: QueryClient,
  queryParams: GetTicketsGet_ticket_dataQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<GetTicketsGetTicketData>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetTicketsGet_ticket_data.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};

/** Download user files by uuid */
export const useGetUsersDownload_file = (
  queryParams: GetUsersDownload_fileQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<GetUsersDownloadFile>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetUsersDownload_file.info(
    queryParams,
    configOverride,
  );
  return useQuery(key, fun, options);
};
useGetUsersDownload_file.info = (
  queryParams: GetUsersDownload_fileQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getUsersDownload_file.key, queryParams] as QueryKey,
    fun: () =>
      getUsersDownload_file(
        queryParams,

        configOverride,
      ),
  };
};
useGetUsersDownload_file.prefetch = (
  client: QueryClient,
  queryParams: GetUsersDownload_fileQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<GetUsersDownloadFile>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetUsersDownload_file.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};

/** Get users and operators actions done for user */
export const useGetUsersGet_actions = (
  queryParams: GetUsersGet_actionsQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<GetUsersGetActions>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetUsersGet_actions.info(queryParams, configOverride);
  return useQuery(key, fun, options);
};
useGetUsersGet_actions.info = (
  queryParams: GetUsersGet_actionsQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getUsersGet_actions.key, queryParams] as QueryKey,
    fun: () =>
      getUsersGet_actions(
        queryParams,

        configOverride,
      ),
  };
};
useGetUsersGet_actions.prefetch = (
  client: QueryClient,
  queryParams: GetUsersGet_actionsQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<GetUsersGetActions>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetUsersGet_actions.info(queryParams, configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};

/** Get specific user bank accounts data */
export const useGetUsersGet_bank_accounts = (
  queryParams: GetUsersGet_bank_accountsQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<GetUsersGetBankAccounts>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetUsersGet_bank_accounts.info(
    queryParams,
    configOverride,
  );
  return useQuery(key, fun, options);
};
useGetUsersGet_bank_accounts.info = (
  queryParams: GetUsersGet_bank_accountsQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getUsersGet_bank_accounts.key, queryParams] as QueryKey,
    fun: () =>
      getUsersGet_bank_accounts(
        queryParams,

        configOverride,
      ),
  };
};
useGetUsersGet_bank_accounts.prefetch = (
  client: QueryClient,
  queryParams: GetUsersGet_bank_accountsQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<GetUsersGetBankAccounts>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetUsersGet_bank_accounts.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};

/** Get specific user bank accounts data */
export const useGetUsersGet_files_list = (
  queryParams: GetUsersGet_files_listQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<GetUsersGetFilesList>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetUsersGet_files_list.info(
    queryParams,
    configOverride,
  );
  return useQuery(key, fun, options);
};
useGetUsersGet_files_list.info = (
  queryParams: GetUsersGet_files_listQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getUsersGet_files_list.key, queryParams] as QueryKey,
    fun: () =>
      getUsersGet_files_list(
        queryParams,

        configOverride,
      ),
  };
};
useGetUsersGet_files_list.prefetch = (
  client: QueryClient,
  queryParams: GetUsersGet_files_listQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<GetUsersGetFilesList>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetUsersGet_files_list.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};

/** Get users data based on filters */
export const useGetUsersGet_users = (
  queryParams?: GetUsersGet_usersQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<GetUsersGetUsers>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetUsersGet_users.info(queryParams, configOverride);
  return useQuery(key, fun, options);
};
useGetUsersGet_users.info = (
  queryParams?: GetUsersGet_usersQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getUsersGet_users.key, queryParams] as QueryKey,
    fun: () =>
      getUsersGet_users(
        queryParams,

        configOverride,
      ),
  };
};
useGetUsersGet_users.prefetch = (
  client: QueryClient,
  queryParams?: GetUsersGet_usersQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<GetUsersGetUsers>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetUsersGet_users.info(queryParams, configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};

/** Get pending withdraws for irt and crypto */
export const useGetWithdrawGet_pendings = (
  options?: SwaggerTypescriptUseQueryOptions<GetWithdrawGetPendings>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetWithdrawGet_pendings.info(configOverride);
  return useQuery(key, fun, options);
};
useGetWithdrawGet_pendings.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getWithdrawGet_pendings.key] as QueryKey,
    fun: () => getWithdrawGet_pendings(configOverride),
  };
};
useGetWithdrawGet_pendings.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<GetWithdrawGetPendings>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetWithdrawGet_pendings.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};

/** Get crypto withdraws data based on filters */
export const useGetWithdrawGet_withdraw_crypto = (
  queryParams?: GetWithdrawGet_withdraw_cryptoQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<GetWithdrawGetWithdrawCrypto>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetWithdrawGet_withdraw_crypto.info(
    queryParams,
    configOverride,
  );
  return useQuery(key, fun, options);
};
useGetWithdrawGet_withdraw_crypto.info = (
  queryParams?: GetWithdrawGet_withdraw_cryptoQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getWithdrawGet_withdraw_crypto.key, queryParams] as QueryKey,
    fun: () =>
      getWithdrawGet_withdraw_crypto(
        queryParams,

        configOverride,
      ),
  };
};
useGetWithdrawGet_withdraw_crypto.prefetch = (
  client: QueryClient,
  queryParams?: GetWithdrawGet_withdraw_cryptoQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<GetWithdrawGetWithdrawCrypto>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetWithdrawGet_withdraw_crypto.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};

/** Get irt withdraws data based on filters */
export const useGetWithdrawGet_withdraw_irt = (
  queryParams?: GetWithdrawGet_withdraw_irtQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<GetWithdrawGetWithdrawIrt>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetWithdrawGet_withdraw_irt.info(
    queryParams,
    configOverride,
  );
  return useQuery(key, fun, options);
};
useGetWithdrawGet_withdraw_irt.info = (
  queryParams?: GetWithdrawGet_withdraw_irtQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getWithdrawGet_withdraw_irt.key, queryParams] as QueryKey,
    fun: () =>
      getWithdrawGet_withdraw_irt(
        queryParams,

        configOverride,
      ),
  };
};
useGetWithdrawGet_withdraw_irt.prefetch = (
  client: QueryClient,
  queryParams?: GetWithdrawGet_withdraw_irtQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<GetWithdrawGetWithdrawIrt>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetWithdrawGet_withdraw_irt.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};

/** Login */
export const usePostLogin = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    PostLogin,
    { requestBody: RequestBodyPostLogin },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postLogin(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Add new operator */
export const usePostOperatorsAdd_operator = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    PostOperatorsAddOperator,
    { requestBody: RequestBodyPostOperatorsAddOperator },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postOperatorsAdd_operator(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Create new ticket */
export const usePostTicketsCreate = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    Success,
    { requestBody: RequestBodyPostTicketsCreate },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postTicketsCreate(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Activate tagged deposit for user */
export const usePostUsersActivate_tagged_deposit = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    Success,
    { requestBody: RequestBodyPostUsersActivateTaggedDeposit },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postUsersActivate_tagged_deposit(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Set_4872_wait */
export const usePostUsersSet_4872_wait = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    Success,
    { requestBody: RequestBodyPostUsersSet4872Wait },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postUsersSet_4872_wait(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Set ir ip check */
export const usePostUsersSet_ir_ip_check = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    Success,
    { requestBody: RequestBodyPostUsersSetIrIP },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postUsersSet_ir_ip_check(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Upload user file */
export const usePostUsersUpload_file = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    Success,
    { requestBody: RequestBodyPostUsersUploadFile },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postUsersUpload_file(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Decrease_balance */
export const usePutBalanceDecrease_balance = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    Success,
    { requestBody: RequestBodyPutBalanceChangeBalance },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return putBalanceDecrease_balance(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Increase_balance */
export const usePutBalanceIncrease_balance = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    Success,
    { requestBody: RequestBodyPutBalanceChangeBalance },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return putBalanceIncrease_balance(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Accept users pending bank card */
export const usePutBank_accountsAccept_card = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    Success,
    { requestBody: RequestBodyPutBankAccountsAcceptCard },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return putBank_accountsAccept_card(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Accept users pending bank iban */
export const usePutBank_accountsAccept_iban = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    Success,
    { requestBody: RequestBodyPutBankAccountsAcceptIban },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return putBank_accountsAccept_iban(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Reject users bank card */
export const usePutBank_accountsReject_card = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    Success,
    { requestBody: RequestBodyPutBankAccountsRejectCard },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return putBank_accountsReject_card(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Reject users bank iban */
export const usePutBank_accountsReject_iban = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    Success,
    { requestBody: RequestBodyPutBankAccountsRejectIban },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return putBank_accountsReject_iban(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Update coin's data */
export const usePutExchange_dataUpdate_coins_data = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    Success,
    { requestBody: RequestBodyPutExchangeDataUpdateCoinsData },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return putExchange_dataUpdate_coins_data(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Accept kyc documents */
export const usePutKycLevel1Accept_kyc_document = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    Success,
    { requestBody: RequestBodyPutUsersAcceptKycDocument },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return putKycLevel1Accept_kyc_document(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Reject kyc documents */
export const usePutKycLevel1Reject_kyc_document = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    Success,
    { requestBody: RequestBodyPutUsersRejectKycDocument },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return putKycLevel1Reject_kyc_document(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Reject landline phone */
export const usePutLandline_phoneReject = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    Success,
    { requestBody: RequestBodyPutLandlinePhoneVerifyReject },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return putLandline_phoneReject(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Verify landline phone */
export const usePutLandline_phoneVerify = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    Success,
    { requestBody: RequestBodyPutLandlinePhoneVerifyReject },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return putLandline_phoneVerify(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Reset operator 2fa secret */
export const usePutOperatorsReset_2fa = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    PostOperatorsAddOperator,
    { requestBody: RequestBodyPutOperatorsReset2fa },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return putOperatorsReset_2fa(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Update operator data */
export const usePutOperatorsUpdate_operator = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    Success,
    { requestBody: RequestBodyPutOperatorsUpdateOperator },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return putOperatorsUpdate_operator(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Add new reply to ticket */
export const usePutTicketsReply = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    Success,
    { requestBody: RequestBodyPutTicketsReply },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return putTicketsReply(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Update ticket */
export const usePutTicketsUpdate = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    Success,
    { requestBody: RequestBodyPutTicketsUpdate },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return putTicketsUpdate(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Add description for user */
export const usePutUsersAdd_description = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    Success,
    { requestBody: RequestBodyPutUsersAddDescription },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return putUsersAdd_description(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Set_upload_permission */
export const usePutUsersSet_auto_withdraw_permission = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    Success,
    { requestBody: RequestBodyPutSetSetUserPermission },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return putUsersSet_auto_withdraw_permission(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Update user birthday */
export const usePutUsersSet_birthday = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    Success,
    { requestBody: RequestBodyPutUsersSetBirthday },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return putUsersSet_birthday(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Update user daily crypto withdraw limit */
export const usePutUsersSet_daily_crypto_withdraw_limit = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    Success,
    { requestBody: RequestBodyPutUsersSetDailyCryptoWithdrawLimit },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return putUsersSet_daily_crypto_withdraw_limit(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Update user daily irt withdraw limit */
export const usePutUsersSet_daily_irt_withdraw_limit = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    Success,
    { requestBody: RequestBodyPutUsersSetDailyIrtWithdrawLimit },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return putUsersSet_daily_irt_withdraw_limit(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Set_deposit_permission */
export const usePutUsersSet_deposit_permission = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    Success,
    { requestBody: RequestBodyPutSetSetUserPermission },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return putUsersSet_deposit_permission(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Update user gender */
export const usePutUsersSet_gender = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    Success,
    { requestBody: RequestBodyPutUsersSetGender },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return putUsersSet_gender(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Update user monthly withdraw limit */
export const usePutUsersSet_monthly_withdraw_limit = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    Success,
    { requestBody: RequestBodyPutUsersSetMonthlyWithdrawLimit },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return putUsersSet_monthly_withdraw_limit(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Update user name and family name */
export const usePutUsersSet_name = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    Success,
    { requestBody: RequestBodyPutUsersSetName },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return putUsersSet_name(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Set_trade_permission */
export const usePutUsersSet_trade_permission = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    Success,
    { requestBody: RequestBodyPutSetSetUserPermission },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return putUsersSet_trade_permission(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Set_upload_permission */
export const usePutUsersSet_upload_permission = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    Success,
    { requestBody: RequestBodyPutSetSetUserPermission },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return putUsersSet_upload_permission(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Update user vip level */
export const usePutUsersSet_vip_level = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    Success,
    { requestBody: RequestBodyPutUsersSetVipLevel },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return putUsersSet_vip_level(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Set_withdraw_permission */
export const usePutUsersSet_withdraw_permission = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    Success,
    { requestBody: RequestBodyPutSetSetUserPermission },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return putUsersSet_withdraw_permission(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Toggle user ban status */
export const usePutUsersToggle_ban = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    Success,
    { requestBody: RequestBodyPutUsersToggleBan },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return putUsersToggle_ban(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Toggle withdraw wait time */
export const usePutUsersToggle_withdraw_wait_time = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    Success,
    { requestBody: RequestBodyPutUsersToggleWithdrawWaitTime },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return putUsersToggle_withdraw_wait_time(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Set crypto withdraw to auto */
export const usePutWithdrawSet_crypto_to_auto = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    Success,
    { requestBody: RequestBodyPutWithdrawSetoAutoAndNotWait },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return putWithdrawSet_crypto_to_auto(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Set irt withdraw to not wait */
export const usePutWithdrawSet_crypto_to_not_wait = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    Success,
    { requestBody: RequestBodyPutWithdrawSetoAutoAndNotWait },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return putWithdrawSet_crypto_to_not_wait(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Set crypto withdraw tx */
export const usePutWithdrawSet_crypto_txhash = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    Success,
    { requestBody: RequestBodyPutWithdrawSetCryptoTxHash },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return putWithdrawSet_crypto_txhash(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Set crypto withdraw description */
export const usePutWithdrawSet_description_crypto = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    Success,
    { requestBody: RequestBodyPutWithdrawSetDescription },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return putWithdrawSet_description_crypto(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Set irt withdraw description */
export const usePutWithdrawSet_description_irt = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    Success,
    { requestBody: RequestBodyPutWithdrawSetDescription },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return putWithdrawSet_description_irt(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Set irt withdraw to auto */
export const usePutWithdrawSet_irt_to_auto = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    Success,
    { requestBody: RequestBodyPutWithdrawSetoAutoAndNotWait },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return putWithdrawSet_irt_to_auto(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Set irt withdraw to not wait */
export const usePutWithdrawSet_irt_to_not_wait = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    Success,
    { requestBody: RequestBodyPutWithdrawSetoAutoAndNotWait },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return putWithdrawSet_irt_to_not_wait(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Set irt withdraw status */
export const usePutWithdrawSet_status_crypto = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    Success,
    { requestBody: RequestBodyPutWithdrawSetStatus },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return putWithdrawSet_status_crypto(
      requestBody,

      configOverride,
    );
  }, options);
};

/** Set irt withdraw status */
export const usePutWithdrawSet_status_irt = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    Success,
    { requestBody: RequestBodyPutWithdrawSetStatus },
    TExtra
  >,
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return putWithdrawSet_status_irt(
      requestBody,

      configOverride,
    );
  }, options);
};
