//@ts-nocheck
/**
 * AUTO_GENERATED Do not change this file directly, use config.ts file instead
 *
 * @version 6
 */

import type { AxiosRequestConfig } from "axios";
import type { SwaggerResponse } from "./config";
import { Http } from "./httpRequest";
//@ts-ignore
import qs from "qs";
import type {
  GetBalanceGetBalance,
  GetBalanceGet_balanceQueryParams,
  GetBankAccountsGetIbanData,
  GetBankAccountsGetPendings,
  GetBank_accountsGet_iban_dataQueryParams,
  GetCaptchaGet,
  GetConfigGetActions,
  GetConfigGetPermissions,
  GetDashboardDataGetData,
  GetDashboardDataSystemHealth,
  GetDepositGetDepositAddress,
  GetDepositGetDepositsCrypto,
  GetDepositGetDepositsIrt,
  GetDepositGet_deposits_cryptoQueryParams,
  GetDepositGet_deposits_irtQueryParams,
  GetDepositGet_deposit_addressQueryParams,
  GetExchangeDataCoins,
  GetExchangeDataMarkets,
  GetManagementDashboardDtaGetBalanceSum,
  GetManagementDashboardDtaGetData,
  GetOperatorsCurrentOperatorData,
  GetOperatorsGetActionsHistory,
  GetOperatorsGetOperator,
  GetOperatorsGet_actions_historyQueryParams,
  GetOperatorsGet_operatorQueryParams,
  GetOperatorsOnlineOperators,
  GetOperatorsPing,
  GetOrdersGetOrders,
  GetOrdersGetTrades,
  GetOrdersGet_ordersQueryParams,
  GetOrdersGet_tradesQueryParams,
  GetTickets,
  GetTicketsGetAgentsData,
  GetTicketsGetTicketData,
  GetTicketsGet_ticket_dataQueryParams,
  GetTicketsQueryParams,
  GetUsersDownloadFile,
  GetUsersDownload_fileQueryParams,
  GetUsersGetActions,
  GetUsersGetBankAccounts,
  GetUsersGetFilesList,
  GetUsersGetPendingKyc,
  GetUsersGetPendingKycSelfieLevel2,
  GetUsersGetUsers,
  GetUsersGet_actionsQueryParams,
  GetUsersGet_bank_accountsQueryParams,
  GetUsersGet_files_listQueryParams,
  GetUsersGet_usersQueryParams,
  GetWithdrawGetPendings,
  GetWithdrawGetWithdrawCrypto,
  GetWithdrawGetWithdrawIrt,
  GetWithdrawGet_withdraw_cryptoQueryParams,
  GetWithdrawGet_withdraw_irtQueryParams,
  PostLogin,
  PostOperatorsAddOperator,
  Success,
} from "./types";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const __DEV__ = process.env.NODE_ENV !== "production";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function overrideConfig(
  config?: AxiosRequestConfig,
  configOverride?: AxiosRequestConfig,
): AxiosRequestConfig {
  return {
    ...config,
    ...configOverride,
    headers: {
      ...config?.headers,
      ...configOverride?.headers,
    },
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function template(path: string, obj: { [x: string]: any } = {}) {
  Object.keys(obj).forEach((key) => {
    const re = new RegExp(`{${key}}`, "i");
    path = path.replace(re, obj[key]);
  });

  return path;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function objToForm(requestBody: object) {
  const formData = new FormData();

  Object.entries(requestBody).forEach(([key, value]) => {
    value && formData.append(key, value);
  });

  return formData;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function objToUrlencoded(requestBody: object) {
  return qs.stringify(requestBody);
}

/** Get specific user balance data */
export const getBalanceGet_balance = (
  queryParams: GetBalanceGet_balanceQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<GetBalanceGetBalance>> => {
  return Http.getRequest(
    getBalanceGet_balance.key,
    queryParams,
    undefined,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getBalanceGet_balance.key = "/balance/get_balance";

/** Get iban data */
export const getBank_accountsGet_iban_data = (
  queryParams: GetBank_accountsGet_iban_dataQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<GetBankAccountsGetIbanData>> => {
  return Http.getRequest(
    getBank_accountsGet_iban_data.key,
    queryParams,
    undefined,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getBank_accountsGet_iban_data.key = "/bank_accounts/get_iban_data";

/** Get pending bank accounts, card and iban */
export const getBank_accountsGet_pendings = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<GetBankAccountsGetPendings>> => {
  return Http.getRequest(
    getBank_accountsGet_pendings.key,
    undefined,
    undefined,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getBank_accountsGet_pendings.key = "/bank_accounts/get_pendings";

/** Get captcha image */
export const getCaptchaGet = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<GetCaptchaGet>> => {
  return Http.getRequest(
    getCaptchaGet.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getCaptchaGet.key = "/captcha/get";

/** Get ariomex error codes json */
export const getCodesError = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x in string | number]: any }>> => {
  return Http.getRequest(
    getCodesError.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getCodesError.key = "/codes/error";

/** Get ariomex success codes json */
export const getCodesSuccess = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x in string | number]: any }>> => {
  return Http.getRequest(
    getCodesSuccess.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getCodesSuccess.key = "/codes/success";

/** Get operators action names and translations */
export const getConfigGet_actions = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<GetConfigGetActions>> => {
  return Http.getRequest(
    getConfigGet_actions.key,
    undefined,
    undefined,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getConfigGet_actions.key = "/config/get_actions";

/** Get ariomex swagger json */
export const getConfigGet_permissions = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<GetConfigGetPermissions>> => {
  return Http.getRequest(
    getConfigGet_permissions.key,
    undefined,
    undefined,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getConfigGet_permissions.key = "/config/get_permissions";

/** Get dashboard data */
export const getDashboard_dataGet_data = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<GetDashboardDataGetData>> => {
  return Http.getRequest(
    getDashboard_dataGet_data.key,
    undefined,
    undefined,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getDashboard_dataGet_data.key = "/dashboard_data/get_data";

/** Get system health */
export const getDashboard_dataSystem_health = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<GetDashboardDataSystemHealth>> => {
  return Http.getRequest(
    getDashboard_dataSystem_health.key,
    undefined,
    undefined,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getDashboard_dataSystem_health.key = "/dashboard_data/system_health";

/** Get specific user deposit address data */
export const getDepositGet_deposit_address = (
  queryParams: GetDepositGet_deposit_addressQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<GetDepositGetDepositAddress>> => {
  return Http.getRequest(
    getDepositGet_deposit_address.key,
    queryParams,
    undefined,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getDepositGet_deposit_address.key = "/deposit/get_deposit_address";

/** Get crypto deposits data based on filters */
export const getDepositGet_deposits_crypto = (
  queryParams?: GetDepositGet_deposits_cryptoQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<GetDepositGetDepositsCrypto>> => {
  return Http.getRequest(
    getDepositGet_deposits_crypto.key,
    queryParams,
    undefined,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getDepositGet_deposits_crypto.key = "/deposit/get_deposits_crypto";

/** Get irt deposits data based on filters */
export const getDepositGet_deposits_irt = (
  queryParams?: GetDepositGet_deposits_irtQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<GetDepositGetDepositsIrt>> => {
  return Http.getRequest(
    getDepositGet_deposits_irt.key,
    queryParams,
    undefined,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getDepositGet_deposits_irt.key = "/deposit/get_deposits_irt";

/** Get all coins data */
export const getExchange_dataCoins = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<GetExchangeDataCoins>> => {
  return Http.getRequest(
    getExchange_dataCoins.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getExchange_dataCoins.key = "/exchange_data/coins";

/** Get all markets data */
export const getExchange_dataMarkets = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<GetExchangeDataMarkets>> => {
  return Http.getRequest(
    getExchange_dataMarkets.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getExchange_dataMarkets.key = "/exchange_data/markets";

/** Get_pending_kyc_level1 */
export const getKycGet_pending_kyc_level1 = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<GetUsersGetPendingKyc>> => {
  return Http.getRequest(
    getKycGet_pending_kyc_level1.key,
    undefined,
    undefined,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getKycGet_pending_kyc_level1.key = "/kyc/get_pending_kyc_level1";

/** Get_pending_kyc_selfie_level2 */
export const getKycGet_pending_kyc_selfie_level2 = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<GetUsersGetPendingKycSelfieLevel2>> => {
  return Http.getRequest(
    getKycGet_pending_kyc_selfie_level2.key,
    undefined,
    undefined,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getKycGet_pending_kyc_selfie_level2.key = "/kyc/get_pending_kyc_selfie_level2";

/** Get sum of all users balance for each coin */
export const getManagement_dashboard_dataGet_balance_sum = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<GetManagementDashboardDtaGetBalanceSum>> => {
  return Http.getRequest(
    getManagement_dashboard_dataGet_balance_sum.key,
    undefined,
    undefined,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getManagement_dashboard_dataGet_balance_sum.key =
  "/management_dashboard_data/get_balance_sum";

/** Get management dashboard data */
export const getManagement_dashboard_dataGet_data = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<GetManagementDashboardDtaGetData>> => {
  return Http.getRequest(
    getManagement_dashboard_dataGet_data.key,
    undefined,
    undefined,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getManagement_dashboard_dataGet_data.key =
  "/management_dashboard_data/get_data";

/** Get current logged-in operator data */
export const getOperatorsCurrent_operator_data = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<GetOperatorsCurrentOperatorData>> => {
  return Http.getRequest(
    getOperatorsCurrent_operator_data.key,
    undefined,
    undefined,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getOperatorsCurrent_operator_data.key = "/operators/current_operator_data";

/** Get operator data */
export const getOperatorsGet_actions_history = (
  queryParams?: GetOperatorsGet_actions_historyQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<GetOperatorsGetActionsHistory>> => {
  return Http.getRequest(
    getOperatorsGet_actions_history.key,
    queryParams,
    undefined,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getOperatorsGet_actions_history.key = "/operators/get_actions_history";

/** Get operator data */
export const getOperatorsGet_operator = (
  queryParams?: GetOperatorsGet_operatorQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<GetOperatorsGetOperator>> => {
  return Http.getRequest(
    getOperatorsGet_operator.key,
    queryParams,
    undefined,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getOperatorsGet_operator.key = "/operators/get_operator";

/** Get online operators */
export const getOperatorsOnline_operators = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<GetOperatorsOnlineOperators>> => {
  return Http.getRequest(
    getOperatorsOnline_operators.key,
    undefined,
    undefined,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getOperatorsOnline_operators.key = "/operators/online_operators";

/** Ping operators */
export const getOperatorsPing = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<GetOperatorsPing>> => {
  return Http.getRequest(
    getOperatorsPing.key,
    undefined,
    undefined,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getOperatorsPing.key = "/operators/ping";

/** Get Orders */
export const getOrdersGet_orders = (
  queryParams?: GetOrdersGet_ordersQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<GetOrdersGetOrders>> => {
  return Http.getRequest(
    getOrdersGet_orders.key,
    queryParams,
    undefined,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getOrdersGet_orders.key = "/orders/get_orders";

/** Get Trades */
export const getOrdersGet_trades = (
  queryParams?: GetOrdersGet_tradesQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<GetOrdersGetTrades>> => {
  return Http.getRequest(
    getOrdersGet_trades.key,
    queryParams,
    undefined,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getOrdersGet_trades.key = "/orders/get_trades";

/** Get ariomex swagger json */
export const getSwagger = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x in string | number]: any }>> => {
  return Http.getRequest(
    getSwagger.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getSwagger.key = "/swagger";

/** Get tickets */
export const getTickets = (
  queryParams?: GetTicketsQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<GetTickets>> => {
  return Http.getRequest(
    getTickets.key,
    queryParams,
    undefined,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getTickets.key = "/tickets";

/** Get Agnets Data */
export const getTicketsGet_agents = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<GetTicketsGetAgentsData>> => {
  return Http.getRequest(
    getTicketsGet_agents.key,
    undefined,
    undefined,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getTicketsGet_agents.key = "/tickets/get_agents";

/** Get ticket data */
export const getTicketsGet_ticket_data = (
  queryParams: GetTicketsGet_ticket_dataQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<GetTicketsGetTicketData>> => {
  return Http.getRequest(
    getTicketsGet_ticket_data.key,
    queryParams,
    undefined,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getTicketsGet_ticket_data.key = "/tickets/get_ticket_data";

/** Download user files by uuid */
export const getUsersDownload_file = (
  queryParams: GetUsersDownload_fileQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<GetUsersDownloadFile>> => {
  return Http.getRequest(
    getUsersDownload_file.key,
    queryParams,
    undefined,
    _CONSTANT2,
    overrideConfig(_CONSTANT3, configOverride),
  );
};

/** Key is end point string without base url */
getUsersDownload_file.key = "/users/download_file";

/** Get users and operators actions done for user */
export const getUsersGet_actions = (
  queryParams: GetUsersGet_actionsQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<GetUsersGetActions>> => {
  return Http.getRequest(
    getUsersGet_actions.key,
    queryParams,
    undefined,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getUsersGet_actions.key = "/users/get_actions";

/** Get specific user bank accounts data */
export const getUsersGet_bank_accounts = (
  queryParams: GetUsersGet_bank_accountsQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<GetUsersGetBankAccounts>> => {
  return Http.getRequest(
    getUsersGet_bank_accounts.key,
    queryParams,
    undefined,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getUsersGet_bank_accounts.key = "/users/get_bank_accounts";

/** Get specific user bank accounts data */
export const getUsersGet_files_list = (
  queryParams: GetUsersGet_files_listQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<GetUsersGetFilesList>> => {
  return Http.getRequest(
    getUsersGet_files_list.key,
    queryParams,
    undefined,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getUsersGet_files_list.key = "/users/get_files_list";

/** Get users data based on filters */
export const getUsersGet_users = (
  queryParams?: GetUsersGet_usersQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<GetUsersGetUsers>> => {
  return Http.getRequest(
    getUsersGet_users.key,
    queryParams,
    undefined,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getUsersGet_users.key = "/users/get_users";

/** Get pending withdraws for irt and crypto */
export const getWithdrawGet_pendings = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<GetWithdrawGetPendings>> => {
  return Http.getRequest(
    getWithdrawGet_pendings.key,
    undefined,
    undefined,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getWithdrawGet_pendings.key = "/withdraw/get_pendings";

/** Get crypto withdraws data based on filters */
export const getWithdrawGet_withdraw_crypto = (
  queryParams?: GetWithdrawGet_withdraw_cryptoQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<GetWithdrawGetWithdrawCrypto>> => {
  return Http.getRequest(
    getWithdrawGet_withdraw_crypto.key,
    queryParams,
    undefined,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getWithdrawGet_withdraw_crypto.key = "/withdraw/get_withdraw_crypto";

/** Get irt withdraws data based on filters */
export const getWithdrawGet_withdraw_irt = (
  queryParams?: GetWithdrawGet_withdraw_irtQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<GetWithdrawGetWithdrawIrt>> => {
  return Http.getRequest(
    getWithdrawGet_withdraw_irt.key,
    queryParams,
    undefined,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getWithdrawGet_withdraw_irt.key = "/withdraw/get_withdraw_irt";

/** Login */
export const postLogin = (
  requestBody: RequestBodyPostLogin,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<PostLogin>> => {
  return Http.postRequest(
    postLogin.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postLogin.key = "/login";

/** Add new operator */
export const postOperatorsAdd_operator = (
  requestBody: RequestBodyPostOperatorsAddOperator,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<PostOperatorsAddOperator>> => {
  return Http.postRequest(
    postOperatorsAdd_operator.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postOperatorsAdd_operator.key = "/operators/add_operator";

/** Create new ticket */
export const postTicketsCreate = (
  requestBody: RequestBodyPostTicketsCreate,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Success>> => {
  return Http.postRequest(
    postTicketsCreate.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postTicketsCreate.key = "/tickets/create";

/** Activate tagged deposit for user */
export const postUsersActivate_tagged_deposit = (
  requestBody: RequestBodyPostUsersActivateTaggedDeposit,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Success>> => {
  return Http.postRequest(
    postUsersActivate_tagged_deposit.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postUsersActivate_tagged_deposit.key = "/users/activate_tagged_deposit";

/** Set_4872_wait */
export const postUsersSet_4872_wait = (
  requestBody: RequestBodyPostUsersSet4872Wait,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Success>> => {
  return Http.postRequest(
    postUsersSet_4872_wait.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postUsersSet_4872_wait.key = "/users/set_4872_wait";

/** Set ir ip check */
export const postUsersSet_ir_ip_check = (
  requestBody: RequestBodyPostUsersSetIrIP,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Success>> => {
  return Http.postRequest(
    postUsersSet_ir_ip_check.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postUsersSet_ir_ip_check.key = "/users/set_ir_ip_check";

/** Upload user file */
export const postUsersUpload_file = (
  requestBody: RequestBodyPostUsersUploadFile,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Success>> => {
  return Http.postRequest(
    postUsersUpload_file.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postUsersUpload_file.key = "/users/upload_file";

/** Decrease_balance */
export const putBalanceDecrease_balance = (
  requestBody: RequestBodyPutBalanceChangeBalance,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Success>> => {
  return Http.putRequest(
    putBalanceDecrease_balance.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putBalanceDecrease_balance.key = "/balance/decrease_balance";

/** Increase_balance */
export const putBalanceIncrease_balance = (
  requestBody: RequestBodyPutBalanceChangeBalance,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Success>> => {
  return Http.putRequest(
    putBalanceIncrease_balance.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putBalanceIncrease_balance.key = "/balance/increase_balance";

/** Accept users pending bank card */
export const putBank_accountsAccept_card = (
  requestBody: RequestBodyPutBankAccountsAcceptCard,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Success>> => {
  return Http.putRequest(
    putBank_accountsAccept_card.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putBank_accountsAccept_card.key = "/bank_accounts/accept_card";

/** Accept users pending bank iban */
export const putBank_accountsAccept_iban = (
  requestBody: RequestBodyPutBankAccountsAcceptIban,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Success>> => {
  return Http.putRequest(
    putBank_accountsAccept_iban.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putBank_accountsAccept_iban.key = "/bank_accounts/accept_iban";

/** Reject users bank card */
export const putBank_accountsReject_card = (
  requestBody: RequestBodyPutBankAccountsRejectCard,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Success>> => {
  return Http.putRequest(
    putBank_accountsReject_card.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putBank_accountsReject_card.key = "/bank_accounts/reject_card";

/** Reject users bank iban */
export const putBank_accountsReject_iban = (
  requestBody: RequestBodyPutBankAccountsRejectIban,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Success>> => {
  return Http.putRequest(
    putBank_accountsReject_iban.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putBank_accountsReject_iban.key = "/bank_accounts/reject_iban";

/** Update coin's data */
export const putExchange_dataUpdate_coins_data = (
  requestBody: RequestBodyPutExchangeDataUpdateCoinsData,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Success>> => {
  return Http.putRequest(
    putExchange_dataUpdate_coins_data.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putExchange_dataUpdate_coins_data.key = "/exchange_data/update_coins_data";

/** Accept kyc documents */
export const putKycLevel1Accept_kyc_document = (
  requestBody: RequestBodyPutUsersAcceptKycDocument,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Success>> => {
  return Http.putRequest(
    putKycLevel1Accept_kyc_document.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putKycLevel1Accept_kyc_document.key = "/kyc/level1/accept_kyc_document";

/** Reject kyc documents */
export const putKycLevel1Reject_kyc_document = (
  requestBody: RequestBodyPutUsersRejectKycDocument,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Success>> => {
  return Http.putRequest(
    putKycLevel1Reject_kyc_document.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putKycLevel1Reject_kyc_document.key = "/kyc/level1/reject_kyc_document";

/** Reject landline phone */
export const putLandline_phoneReject = (
  requestBody: RequestBodyPutLandlinePhoneVerifyReject,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Success>> => {
  return Http.putRequest(
    putLandline_phoneReject.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putLandline_phoneReject.key = "/landline_phone/reject";

/** Verify landline phone */
export const putLandline_phoneVerify = (
  requestBody: RequestBodyPutLandlinePhoneVerifyReject,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Success>> => {
  return Http.putRequest(
    putLandline_phoneVerify.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putLandline_phoneVerify.key = "/landline_phone/verify";

/** Reset operator 2fa secret */
export const putOperatorsReset_2fa = (
  requestBody: RequestBodyPutOperatorsReset2fa,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<PostOperatorsAddOperator>> => {
  return Http.putRequest(
    putOperatorsReset_2fa.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putOperatorsReset_2fa.key = "/operators/reset_2fa";

/** Update operator data */
export const putOperatorsUpdate_operator = (
  requestBody: RequestBodyPutOperatorsUpdateOperator,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Success>> => {
  return Http.putRequest(
    putOperatorsUpdate_operator.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putOperatorsUpdate_operator.key = "/operators/update_operator";

/** Add new reply to ticket */
export const putTicketsReply = (
  requestBody: RequestBodyPutTicketsReply,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Success>> => {
  return Http.putRequest(
    putTicketsReply.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putTicketsReply.key = "/tickets/reply";

/** Update ticket */
export const putTicketsUpdate = (
  requestBody: RequestBodyPutTicketsUpdate,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Success>> => {
  return Http.putRequest(
    putTicketsUpdate.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putTicketsUpdate.key = "/tickets/update";

/** Add description for user */
export const putUsersAdd_description = (
  requestBody: RequestBodyPutUsersAddDescription,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Success>> => {
  return Http.putRequest(
    putUsersAdd_description.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putUsersAdd_description.key = "/users/add_description";

/** Set_upload_permission */
export const putUsersSet_auto_withdraw_permission = (
  requestBody: RequestBodyPutSetSetUserPermission,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Success>> => {
  return Http.putRequest(
    putUsersSet_auto_withdraw_permission.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putUsersSet_auto_withdraw_permission.key =
  "/users/set_auto_withdraw_permission";

/** Update user birthday */
export const putUsersSet_birthday = (
  requestBody: RequestBodyPutUsersSetBirthday,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Success>> => {
  return Http.putRequest(
    putUsersSet_birthday.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putUsersSet_birthday.key = "/users/set_birthday";

/** Update user daily crypto withdraw limit */
export const putUsersSet_daily_crypto_withdraw_limit = (
  requestBody: RequestBodyPutUsersSetDailyCryptoWithdrawLimit,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Success>> => {
  return Http.putRequest(
    putUsersSet_daily_crypto_withdraw_limit.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putUsersSet_daily_crypto_withdraw_limit.key =
  "/users/set_daily_crypto_withdraw_limit";

/** Update user daily irt withdraw limit */
export const putUsersSet_daily_irt_withdraw_limit = (
  requestBody: RequestBodyPutUsersSetDailyIrtWithdrawLimit,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Success>> => {
  return Http.putRequest(
    putUsersSet_daily_irt_withdraw_limit.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putUsersSet_daily_irt_withdraw_limit.key =
  "/users/set_daily_irt_withdraw_limit";

/** Set_deposit_permission */
export const putUsersSet_deposit_permission = (
  requestBody: RequestBodyPutSetSetUserPermission,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Success>> => {
  return Http.putRequest(
    putUsersSet_deposit_permission.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putUsersSet_deposit_permission.key = "/users/set_deposit_permission";

/** Update user gender */
export const putUsersSet_gender = (
  requestBody: RequestBodyPutUsersSetGender,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Success>> => {
  return Http.putRequest(
    putUsersSet_gender.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putUsersSet_gender.key = "/users/set_gender";

/** Update user monthly withdraw limit */
export const putUsersSet_monthly_withdraw_limit = (
  requestBody: RequestBodyPutUsersSetMonthlyWithdrawLimit,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Success>> => {
  return Http.putRequest(
    putUsersSet_monthly_withdraw_limit.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putUsersSet_monthly_withdraw_limit.key = "/users/set_monthly_withdraw_limit";

/** Update user name and family name */
export const putUsersSet_name = (
  requestBody: RequestBodyPutUsersSetName,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Success>> => {
  return Http.putRequest(
    putUsersSet_name.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putUsersSet_name.key = "/users/set_name";

/** Set_trade_permission */
export const putUsersSet_trade_permission = (
  requestBody: RequestBodyPutSetSetUserPermission,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Success>> => {
  return Http.putRequest(
    putUsersSet_trade_permission.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putUsersSet_trade_permission.key = "/users/set_trade_permission";

/** Set_upload_permission */
export const putUsersSet_upload_permission = (
  requestBody: RequestBodyPutSetSetUserPermission,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Success>> => {
  return Http.putRequest(
    putUsersSet_upload_permission.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putUsersSet_upload_permission.key = "/users/set_upload_permission";

/** Update user vip level */
export const putUsersSet_vip_level = (
  requestBody: RequestBodyPutUsersSetVipLevel,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Success>> => {
  return Http.putRequest(
    putUsersSet_vip_level.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putUsersSet_vip_level.key = "/users/set_vip_level";

/** Set_withdraw_permission */
export const putUsersSet_withdraw_permission = (
  requestBody: RequestBodyPutSetSetUserPermission,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Success>> => {
  return Http.putRequest(
    putUsersSet_withdraw_permission.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putUsersSet_withdraw_permission.key = "/users/set_withdraw_permission";

/** Toggle user ban status */
export const putUsersToggle_ban = (
  requestBody: RequestBodyPutUsersToggleBan,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Success>> => {
  return Http.putRequest(
    putUsersToggle_ban.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putUsersToggle_ban.key = "/users/toggle_ban";

/** Toggle withdraw wait time */
export const putUsersToggle_withdraw_wait_time = (
  requestBody: RequestBodyPutUsersToggleWithdrawWaitTime,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Success>> => {
  return Http.putRequest(
    putUsersToggle_withdraw_wait_time.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putUsersToggle_withdraw_wait_time.key = "/users/toggle_withdraw_wait_time";

/** Set crypto withdraw to auto */
export const putWithdrawSet_crypto_to_auto = (
  requestBody: RequestBodyPutWithdrawSetoAutoAndNotWait,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Success>> => {
  return Http.putRequest(
    putWithdrawSet_crypto_to_auto.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putWithdrawSet_crypto_to_auto.key = "/withdraw/set_crypto_to_auto";

/** Set irt withdraw to not wait */
export const putWithdrawSet_crypto_to_not_wait = (
  requestBody: RequestBodyPutWithdrawSetoAutoAndNotWait,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Success>> => {
  return Http.putRequest(
    putWithdrawSet_crypto_to_not_wait.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putWithdrawSet_crypto_to_not_wait.key = "/withdraw/set_crypto_to_not_wait";

/** Set crypto withdraw tx */
export const putWithdrawSet_crypto_txhash = (
  requestBody: RequestBodyPutWithdrawSetCryptoTxHash,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Success>> => {
  return Http.putRequest(
    putWithdrawSet_crypto_txhash.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putWithdrawSet_crypto_txhash.key = "/withdraw/set_crypto_txhash";

/** Set crypto withdraw description */
export const putWithdrawSet_description_crypto = (
  requestBody: RequestBodyPutWithdrawSetDescription,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Success>> => {
  return Http.putRequest(
    putWithdrawSet_description_crypto.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putWithdrawSet_description_crypto.key = "/withdraw/set_description_crypto";

/** Set irt withdraw description */
export const putWithdrawSet_description_irt = (
  requestBody: RequestBodyPutWithdrawSetDescription,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Success>> => {
  return Http.putRequest(
    putWithdrawSet_description_irt.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putWithdrawSet_description_irt.key = "/withdraw/set_description_irt";

/** Set irt withdraw to auto */
export const putWithdrawSet_irt_to_auto = (
  requestBody: RequestBodyPutWithdrawSetoAutoAndNotWait,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Success>> => {
  return Http.putRequest(
    putWithdrawSet_irt_to_auto.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putWithdrawSet_irt_to_auto.key = "/withdraw/set_irt_to_auto";

/** Set irt withdraw to not wait */
export const putWithdrawSet_irt_to_not_wait = (
  requestBody: RequestBodyPutWithdrawSetoAutoAndNotWait,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Success>> => {
  return Http.putRequest(
    putWithdrawSet_irt_to_not_wait.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putWithdrawSet_irt_to_not_wait.key = "/withdraw/set_irt_to_not_wait";

/** Set irt withdraw status */
export const putWithdrawSet_status_crypto = (
  requestBody: RequestBodyPutWithdrawSetStatus,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Success>> => {
  return Http.putRequest(
    putWithdrawSet_status_crypto.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putWithdrawSet_status_crypto.key = "/withdraw/set_status_crypto";

/** Set irt withdraw status */
export const putWithdrawSet_status_irt = (
  requestBody: RequestBodyPutWithdrawSetStatus,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Success>> => {
  return Http.putRequest(
    putWithdrawSet_status_irt.key,
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putWithdrawSet_status_irt.key = "/withdraw/set_status_irt";
export const _CONSTANT0 = {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
};
export const _CONSTANT1 = [];
export const _CONSTANT2 = [{ accessToken: "arx-a" }];
export const _CONSTANT3 = {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/octet-stream",
  },
};
